import React, { FC,useState, FormEvent } from 'react';
import Knight from '../Assets/knight.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ResetPassword.css'

// interface ResetPasswordParams {
//     token: string;
// }

const wrapper = {
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.60)',
};


const mainContainer = {
    justifyContent: 'center',
    padding: '6.25rem 6.125rem',
    alignItems: 'center',
    borderRadius: '0.9375rem',
    // border: '2.5px solid rgba(247, 147, 26, 0.25)',
    background: 'rgba(33, 51, 63, 0.01)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05), 0px 4px 56.1px 0px rgba(204, 199, 123, 0.08)',
    // backdropFilter: 'blur(71.5622329711914px)',
    margin: 'auto',
};

const bgImg = {
    width: '19.8125rem',
    height: '100%',
    right: '0rem',
    zIndex: -2,
};

const midContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5.25rem',
};

const topCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '3rem',
};

const topicCon = {
    alignItems: 'center',
    gap: '1.25rem',
};

const topic = {
    alignItems: 'center',
    gap: '0.375rem',
    color: 'var(--White, #FFF)',
    fontFamily: 'Poppins',
    fontSize: '2.625rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '112%', /* 2.94rem */
};

const des = {
    color: '#FFF',
    // textAlign: 'center',
    /* Body - Secondary/Body - Base/Medium */
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const inputCon = {
    color: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2.25rem',
    alignSelf: 'stretch',
};

const inputs = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
};

const fpbCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.75rem',
}

const input = {
    color: '#FFF',
    width: '23.375rem',
    padding: '0.75rem 1rem',
    alignItems: 'center',
    gap: '1.23719rem',
    borderRadius: '0.1875rem',
    border: '1px solid #838383',
    background:' rgba(0, 3, 17, 0.24)',
};


const fpb = {
    color: '#CCC77B',
    // textAlign: 'right',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '0.75rem', /* 100% */
};

const signInBtn = {
    padding: '0.5rem 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.23719rem',
    alignSelf: 'stretch',
    borderRadius: '0.1875rem',
    border: '1px solid #CCC77B',
    background: 'rgba(247, 147, 26, 0.25)',
    color: '#CCC77B',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
}

const signup = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
}

const signupDes = {
    color: '#FFF',
    // textAlign: 'center',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '0.75rem',
}

const signupBtn = {
    color: '#F7931A',
    // textAlign: 'center',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const ResetPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const { token } = useParams< {token: string} >();
    const navigate = useNavigate();


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
          setMessage('Passwords do not match.');
          return;
        }
    
        try {
          const response = await axios.patch(`/api/reset-password`, {
            token,
            newPassword
          });

          // Assuming the response contains a 'token' field with the JWT
          const jwtToken = response.data.token;

          // Store the token in local storage or your state management solution
          localStorage.setItem('authToken', jwtToken); 
          
          // Optionally set the axios default header if you want to include the token in all subsequent requests
          axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

          // Update message to indicate success
          setMessage('Your password has been reset successfully.');

          // Redirect user or perform other actions after successful password reset
          navigate('/App');

        } catch (error: any) {
            setMessage(error.response?.data.message || 'An error occurred.');
        }
    };


  return (
    <div className='flex' style={wrapper}>
    <div style={mainContainer} className='inline-flex reCon'>
      
      <div style={midContainer} className='flex flex-col'>
        <div style={topCon} className='flex flex-col'>
            <div style={topicCon} className='flex flex-col'>
                <p style={topic} className='flex'>BE A KNIGHT</p>
                <p style={des}>Be ready for the battle</p>
            </div>
            <form onSubmit={handleSubmit} style={inputCon} className='flex flex-col'>
                <div style={inputs} className='flex flex-col'>
                    <div>
                        <label htmlFor="newPassword">Enter a New Password</label>
                        <input 
                            style={input} 
                            className='flex' 
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            style={input} 
                            className='flex'
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        </div>
                </div> 
                <button style={signInBtn} className='flex' type="submit">Reset Password</button>              
            </form>
            {message && <p>{message}</p>}
        </div>
      </div>
      <img src={Knight} alt="knight" style={bgImg} className='absolute' />
    </div>
    </div>
  );
};

export default ResetPassword;


