// import axios from 'axios';
import confetti from 'canvas-confetti';
import type { configObj } from 'js-chess-engine';
// import { useNavigate } from 'react-router-dom';
import {
  FC,
  memo,
  // MouseEventHandler,
  useContext,
  useEffect,
  useState
} from 'react';
import { useCatchKeyboardPress } from '../Hooks';
import { cxChessdata } from './Chessdata';
import Modal from './Modal';

const ModalFinishGame: FC = () => {
  // const navigate = useNavigate();
  const { data } = useContext(cxChessdata);
  const [open, setOpen] = useState(false);



  useEffect(() => {
    if (data == null) return;
    let openModal = data.isFinished && data.fullMove > 1;
    setOpen(openModal);
    if (openModal) {
      confetti({});
      
    }
  }, [data]);

  const onClose = () => {
    setOpen(false);
    window.location.reload(); // Add this line to reload the page
  };
  useCatchKeyboardPress(() => {
    if (!open) return null;
    setOpen(false);
  }, ['Escape']);

  if (!open) return null;
  let winner: configObj['turn'] = !data!.checkMate
    ? data!.turn
    : data!.turn === 'black'
    ? 'white'
    : 'black';
  const title = `${winner} won`;
  const body = `${winner} won the game in ${data?.fullMove} moves`;
  return <Modal onClose={onClose} body={body} title={title} />;
};

export default memo(ModalFinishGame);
