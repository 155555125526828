import { FC, memo, useEffect, useState } from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WithdrawButton from '../Settings/WithdrawButton';
import DepositButton from '../Settings/DepositButton';
import './UserProfile.css'
import Avatar1 from '../../../images/avatar1.png';
import Avatar2 from '../../../images/avatar2.png';
import Avatar3 from '../../../images/avatar3.png';
import Avatar4 from '../../../images/avatar4.png';

interface UserProfileProps {
  isButtonClicked: boolean;
  accBal: number;
  username: string;
  totalMatches: number;
  memberSince: string;
}

// const containerStyle = (isButtonClicked: boolean) => ({
//   display: 'inline-flex',
//   padding: '1.39713rem 1.39713rem 1.39713rem 0rem',
//   alignItems: 'center',
//   gap: '1.39713rem',
//   borderRadius: '0.43663rem',  
//   background: isButtonClicked ? 'rgba(255, 0, 0, 0.14)' : 'rgba(255, 255, 255, 0.06)', // Use the prop here
//   backdropFilter: 'blur(2.794297218322754px)',
// });

const avatar = {
  // width: '6.54913rem',
  // height: '6.54913rem',
  // outline: '8.383px solid #353B3F',
  // // position: 'absolute',
  // left: '0rem',
  // // top: '1.39713rem',
  // borderRadius: '0.34931rem',
  // background: 'url(<path-to-image>), #7f7f7f 50% / cover no-repeat',
};

const info = {
  paddingLeft: '7.85894rem',
  alignItems: 'flex-start',
  gap: '0.5rem',
}

const details = {
  alignItems: 'flex-start',
  gap: '-0.0625rem',
  width: '12.2rem',
  // height: '4.31rem',
  overflow: 'hidden',
}

const Label = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#CCC',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '1.2225rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
}

const LabelU = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#CCC',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '1.2225rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
  width: '100%',
}

const DataU = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#CCC',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '1.39713rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
  width: '5rem',
  height: '1.875rem',
  overflow: 'hidden',
}

const Data = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  color: '#CCC',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '1.39713rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
}

const fmDate = {
  color: '#E2E3E5',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Open Sans',
  fontSize: '0.52394rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
}

const cta = {
  justifyContent: 'center',
  alignItems: 'center',
  
}

const AccBal = {
  padding: '0.34313rem 0.68625rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.57188rem',
  alignSelf: 'stretch',
  borderRadius: '0.1875rem',
  background: '#193A46',

  color: '#FFF',
  fontFamily: 'Onyx',
  fontSize: '1.39713rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.18163rem',
}

const DnWbtn = {
  alignItems: 'flex-start',
  
}

const UserProfile: FC<UserProfileProps> = ({ isButtonClicked, accBal, username, totalMatches, memberSince }) => {
  const [animateUsername, setAnimateUsername] = useState(false);

  const containerStyle = (isButtonClicked: boolean) => ({
    display: 'inline-flex',
    padding: '1.39713rem 1.39713rem 1.39713rem 0',
    alignItems: 'center',
    gap: '1.39713rem',
    borderRadius: '0.43663rem',
    background: isButtonClicked ? 'rgba(255, 0, 0, 0.14)' : 'rgba(255, 255, 255, 0.06)',
    backdropFilter: 'blur(2.794297218322754px)',
  });
  

  useEffect(() => {
    if (username.length > 7) {
      setAnimateUsername(true);
    }
  }, [username]);

  const displayedUsername = animateUsername ? username : username.slice(0, 7);
  // lg:mr-10

   // Function to determine avatar path
   const getAvatarPath = (matches: number) => {
    if (matches < 100) {
        return Avatar1;
    } else if (matches >= 100 && matches < 500) {
        return Avatar2;
    } else if (matches >= 500 && matches < 1000) {
        return Avatar3;
    } else { // matches >= 1000
        return Avatar4;
    }
};

// Get the appropriate avatar path based on totalMatches
const avatarPath = getAvatarPath(totalMatches);

return (
  <div style={containerStyle(isButtonClicked)} className='flex flex-row' id='mainCon'>  
    <div style={avatar} className='absolute' id='imgCon'>
      <img 
          src={avatarPath} 
          alt="Avatar"
      />
    </div>
    <div style={info} className='flex flex-col'>
      <div className='flex flex-col' style={details}>
          <div style={Label}>Username : <span style={Data}>
          {displayedUsername}
              {/* <p className={animateUsername ? 'animate-username' : ''}> {displayedUsername}</p> */}
            </span>
          </div>
        <p style={Label}>Total matches: <span style={Data}>{totalMatches}</span></p>
        <p style={fmDate}>Worrior since {memberSince}</p>
      </div>
      <div style={cta} className='flex' id='Con'>
        <div style={AccBal} className='flex'>{accBal.toFixed(2)}USD</div>
        <div style={DnWbtn} className='flex' id='subCon'>
          <WithdrawButton />
          <DepositButton />
        </div>
      </div>
    </div>
  </div>
);
};

export default memo(UserProfile);
