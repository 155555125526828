import React, { FC, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import btcIcon from '../Assets/btcIcon.svg';
import ethIcon from '../Assets/ethIcon.svg';
import tethIcon from '../Assets/tethIcon.svg';
import './Withdraw.css'
import axios from 'axios';


interface WithdrawProps {
    onClose: () => void ;
}

const modalContainer = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.60)',
    backdropFilter: 'blur(10px)',
    // alignItems: 'center',
    // justifyContent: 'center',
};

const modelContent = {
    // background: '#fff',
    // padding: '20px',
    // borderRadius: '8px',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    // maxWidth: '80%',
    // maxHeight: '80%',
    // overflow: 'auto',
    padding: '2.1875rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    borderRadius: '0.9375rem',
    border: '2.5px solid #21333F',
    background: 'rgba(33, 51, 63, 0.01)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
};

const closebtn = {
    padding: '0.1875rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.625rem',
    alignSelf: 'stretch',
    cursor: 'pointer',
};

const mainContainer = {
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2.6875rem',
};

const note = {
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const withdrawMethods = {
    // width: '44.625rem',
    alignItems: 'center',
    gap: '1.125rem',
}

const line = {
    width: '100%',
    height: '0.0625rem',
    background: 'rgba(255, 255, 255, 0.2)',
}

const bottom = {
    // width: '42.5rem',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '2.6875rem',
}

const row = {
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '1.875rem',
    alignSelf: 'stretch',
}

const btn1 = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid #F7931A',
    background: 'rgba(247, 147, 26, 0.29)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const btn2 = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid rgba(98, 126, 234, 0.35)',
    background: 'rgba(26, 114, 247, 0.10)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const btnT = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid rgba(38, 161, 123, 0.35)',
    background: ' rgba(26, 247, 154, 0.10)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const left = {
    // width: '21.375rem',
    alignItems: 'flex-start',
    gap: '2.0625rem',
    flexShrink: '0',
}

// const right = {
//     alignItems: 'flex-start',
//     gap: '0.375rem',
//     flex: '1 0 0',
// }

// const info = {
//     alignItems: 'flex-start',
//     gap: '1.0625rem',
//     alignSelf: 'stretch',
// }

// const steps = {
//     alignItems: 'flex-start',
//     gap: '0.25rem',
//     alignSelf: 'stretch',
// }

// const Label = {
//     color: '#FFF',
//     fontFamily: 'Open Sans',
//     fontSize: '1rem',
//     fontStyle: 'normal',
//     fontWeight: '600',
//     lineHeight: 'normal',
// }

// const Data = { 
//     flex: '1 0 0',
//     alignSelf: 'stretch',
//     color: 'rgba(255, 255, 255, 0.50)',
//     fontFamily: 'Open Sans',
//     fontSize: '0.875rem',
//     fontStyle: 'normal',
//     fontWeight: '600',
//     lineHeight: 'normal',
// }

// const depositPart = {
//     alignItems: 'center',
//     gap: '1rem',
//     alignSelf: 'stretch',
// }

// const finalPart = {
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '1rem',
//     alignSelf: 'stretch',
// }

const input = {
    color: `#FFF`,
    width: '100%',
    padding: '0.75rem 1rem',
    alignItems: 'center',
    gap: '1.23719rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1px solid #838383',
    background:' rgba(0, 3, 17, 0.24)',
}

// const depositBtn = {
//     width: '6.75rem',
//     padding: '0.6875rem 1rem',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '1.23719rem',
//     borderRadius: '0.375rem',
//     border: '1px solid #CCC77B',
//     background: 'rgba(247, 147, 26, 0.25)',
// }

// const qrContainer = {
//     height: '21.4375rem',
//     alignItems: 'center',
//     gap: '0.75rem',
//     alignSelf: 'stretch',
//     background: 'rgba(64, 35, 0, 0.25)',
// }

// const addressContainer = {
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '0.625rem',
//     alignSelf: 'stretch',
// }

const address = {
    // color: 'rgba(255, 255, 255, 0.44)',
    fontFamily: 'Open Sans',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const formContainer = {
    width: '100%',
    gap: '1rem',
}

const pwdContainer = {
    width: '100%',
    gap: '0.4rem',
}

const withdrawButton = {
    width: '100%',
    // color: '#ffffffae',
    justifyContent: 'center',
    padding: '0.75rem 1rem',
    borderRadius: '0.375rem',
    border: '1px solid #001995',
    // background: '#020443',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const btright = {
    alignItems: 'center',
    gap: '1.625rem',
    flex: '1 0 0',
    alignSelf: 'stretch',
}

const withdrawAmount = {
    alignSelf: 'stretch',
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const down = {
    alignItems: 'center',
    gap: '1.625rem',
    alignSelf: 'stretch',
}

const one = {
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    alignSelf: 'stretch',
}

const two = {
    color: '#8B8B8B',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    alignSelf: 'stretch',
}



const CloseIcon: FC = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M24.1096 0.390466C23.589 -0.130155 22.7448 -0.130155 22.2243 0.390466L12.5 10.1147L2.77577 0.390466C2.25516 -0.130155 1.41107 -0.130155 0.890466 0.390466C0.369845 0.911075 0.369845 1.75516 0.890466 2.27577L10.6147 12L0.890493 21.7242C0.369871 22.2449 0.369871 23.0889 0.890493 23.6096C1.4111 24.1302 2.25519 24.1302 2.7758 23.6096L12.5 13.8853L22.2243 23.6096C22.7448 24.1302 23.589 24.1302 24.1096 23.6096C24.6301 23.0889 24.6301 22.2449 24.1096 21.7243L14.3853 12L24.1096 2.27577C24.6301 1.75516 24.6301 0.911075 24.1096 0.390466Z" fill="#21333F"/>
    </svg>
  );

  


const Withdraw: FC<WithdrawProps> = ({ onClose }) => {
    const modelRoot = document.getElementById('modal-root') || document.body;
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [selectedMethod, setSelectedMethod] = useState<string | null>(null); // Added state for selected method
    const navigate = useNavigate();
    const [accBal, setAccBal] = useState<number>(0);
    const [userData, setUserData] = useState({  accBal: 0});

    // New state hooks for form inputs
    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
                window.location.reload();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    // Method to handle click on payment method buttons
    const handleSelectMethod = (method: string) => {
        setSelectedMethod(method);
    };

    const handleForgotPasswordClick = () => {
        navigate('/forgotpassword');
    };


    // Function to handle withdrawal request
    const handleWithdraw = async (event: { preventDefault: () => void; }) => {
         // Prevent the default form submit action
        event.preventDefault();
        const userId = localStorage.getItem('userID');
        try {
            const response = await axios.post(`/api/withdraw`, {
                 userId, 
                 amount, 
                 walletAddress, 
                 password,
                 selectedMethod,
            },{
                timeout: 10000,
            });

            console.log('withdraw request successful:', response.data);

            // Handle successful response here
            alert('Withdrawal request sent. Please check your email for confirmation.');
        } catch (error) {
            console.error('Withdrawal error:', error);
            alert('Error processing withdrawal.');
        }
    };


    // Style for the selected button
    const selectedButtonStyle = {
        border: '1.5px solid #ffffff', // Example border color for selected state
        // background: 'rgba(0, 255, 0, 0.2)', // Example background color for selected state
    };

    // Function to merge styles based on selection
    const getButtonStyle = (methodStyle: React.CSSProperties, method: string) => {
        return selectedMethod === method ? { ...methodStyle, ...selectedButtonStyle } : methodStyle;
    };

    const reload = () => {
        window.location.reload();
    }

    useEffect(() => {
        // Fetch user data here, then set it to state
        const userId = localStorage.getItem('userID');
        console.log('User ID:', userId);
        if (userId) {
          axios.get(`/api/users/user/${userId}`) // Replace with your actual API endpoint
          .then(response => {
            setAccBal(response.data.accBal);
            // console.log('Fetched User data:', response.data);
            setUserData({
              accBal: response.data.accBal,
            });
          })
            .catch(error => {
              console.error('Error fetching user data:', error);
              // Handle error appropriately
            });
        }
      }, []);
    

  return ReactDOM.createPortal(
    <div style={modalContainer} className='fixed flex modCon'>
        <div style={modelContent} className='relative flex-col modaCon' ref={modalRef}>
            <div style={closebtn} className='flex' onClick={onClose} onMouseEnter={reload}>
               <CloseIcon />
            </div>   
            <div style={mainContainer} className='flex flex-col diga'>
                <p style={note}>Payment Details</p>
                <div style={withdrawMethods} className='flex pMeth'>
                    <div style={row} className='flex kelin'>
                        <button
                            style={getButtonStyle(btn1, 'btc')} // Apply conditional styling
                            className='flex fWidth'
                            onClick={() => handleSelectMethod('btc')}>
                            <img src={btcIcon} alt='btc' />
                            BITCOIN
                        </button>
                        <button 
                            style={getButtonStyle(btn2, 'eth')} // Apply conditional styling
                            className='flex fWidth'
                            onClick={() => handleSelectMethod('eth')}>
                            <img src={ethIcon} alt='eth' />
                            ETHEREUM ERC20
                        </button>
                    </div>
                    <div style={row} className='flex kelin'>
                        <button 
                            style={getButtonStyle(btnT, 'teth1')} 
                            className='flex fWidth'
                            onClick={() => handleSelectMethod('teth1')}>
                            <img src={tethIcon} alt='btc' />
                            THETHER TRC20
                        </button>
                        <button 
                            style={getButtonStyle(btnT, 'teth2')} 
                            className='flex fWidth'
                            onClick={() => handleSelectMethod('teth2')}>
                            <img src={tethIcon} alt='btc' />
                            THETHER ERC20
                        </button>
                    </div>
                </div>
                <div style={line}></div>
                <div style={bottom} className='flex bot'>
                    <div style={left} className='flex flex-col lft'>
                        <form style={formContainer} className='flex flex-col' onSubmit={handleWithdraw}>
                            <input style={input} type='text' placeholder='Amount' value={amount} onChange={e => setAmount(e.target.value)}  />
                            <input style={input} type='text' placeholder='Wallet Address' value={walletAddress} onChange={e => setWalletAddress(e.target.value)}/>
                            <div style={pwdContainer} className='flex flex-col'>
                                <input style={input} type='password' placeholder='Password'  value={password} onChange={e => setPassword(e.target.value)}/>
                                <button style={address} className='text-right fpb' onClick={handleForgotPasswordClick}>forgot password</button>
                            </div>
                            <button 
                                style={withdrawButton} 
                                className='flex withBtn' 
                                type='submit'
                                disabled = {Number(amount) > accBal}
                            >WITHDRAW</button>
                        </form>
                    </div>
                    <div style={btright} className='flex flex-col'>
                        <p style={withdrawAmount}>Available for withdrawal : {accBal}USD</p>
                        <div style={down} className='flex flex-col'>
                            <p style={one}>The minimum withdrawal amount is 20USD. Carefully enter the address. The transaction will be lost if the address is incorrect.</p>
                            <p style={two}>Please note that allow five to twelve  hours for the system/blockchain to complete the transaction.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>,
    modelRoot
  );
};

export default Withdraw;
