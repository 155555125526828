

import React, { useState, useCallback, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Filter.module.css';
import FilterIcon from '../assets/filterIcon.svg';
import ChevronDown from '../assets/chevronDown.svg';
import ResetIcon from '../assets/resetIcon.svg';

const MemoizedDatePicker = memo(DatePicker);

interface FilterProps {
  onFiltersChange: (filters: any) => void; // Adjusted to a more generic callback for all filter changes
}

const Filter: React.FC<FilterProps> = ({ onFiltersChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleStartDateChange = useCallback((date: Date | null) => {
    setStartDate(date);
    onFiltersChange({ startDate: date });
  }, [onFiltersChange]);

  const handleEndDateChange = useCallback((date: Date | null) => {
    setEndDate(date);
    onFiltersChange({ endDate: date });
  }, [onFiltersChange]);

  const handlePaymentMethodChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const method = event.target.value;
    setPaymentMethod(method);
    onFiltersChange({ paymentMethod: method });
  }, [onFiltersChange]);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setPaymentMethod('');
    onFiltersChange({ startDate: null, endDate: null, paymentMethod: '' });
  };

  return (
    <div className={styles.filterContainer}>
        <div className={styles.filterTitle}>
            <img src={FilterIcon} alt="Filter Icon" />
            <span id='filterby'>Filter by</span>
        </div>
        {/* Date Range Picker */}
        <div className={styles.dateRangePicker}>
            <MemoizedDatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                isClearable
                placeholderText="Start Date"
            />
            <MemoizedDatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable
                placeholderText="End Date"
            />
        </div>
        {/* Deposit or Withdrawal Select */}
        <div className={styles.customSelectWrapper}>
            <select
                className={styles.filterSelect}
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
            >
                <option value="Deposits">Deposits</option>
                <option value="Withdrawals">Withdrawals</option>
            </select>
            <img src={ChevronDown} alt="Chevron Down" className={styles.customChevron} />
        </div>

        <button onClick={handleReset} className={styles.resetButton}>
            <img src={ResetIcon} alt="Reset Icon" />
            <p>Reset Filters</p>
        </button>
    </div>
  );
};

export default Filter;
