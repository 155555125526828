import React from 'react';
import styles from './NavBar.module.css';

interface NavbarProps {
  // Define any props here if needed
}

const Navbar: React.FC<NavbarProps> = (props) => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>Dashboard</div>
      {/* Add more navigation items here if needed */}
    </nav>
  );
};

export default Navbar;