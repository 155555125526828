import { FC, memo, MouseEventHandler, useContext, useRef } from 'react';
import { cxChessengine } from '../../Chessengine';
import { useOnClickOutside } from '../../Hooks';
import PrintHelper from './PrintHelper';
import PrintPiece from './PrintPiece';
import './Chessboard.css';

// Add a new type for the props, including className
type ChessboardProps = {
  className?: string;
};

const Chessboard: FC<ChessboardProps> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setSelectedCell } = useContext(cxChessengine);
  const onClickHandler: MouseEventHandler = () => {
    setSelectedCell();
  };
  useOnClickOutside(ref, () => {
    setSelectedCell();
  });

  // Combine the provided className with the existing classes
  const combinedClassName = `relative mx-auto h-vw-5 w-vw-5 bg-Chessboard-2 bg-contain bg-no-repeat lg:h-vh-5 lg:max-h-[42rem] lg:min-h-[28rem] lg:w-vh-5 lg:min-w-[28rem] lg:max-w-[42rem] xl:max-h-[62rem] xl:max-w-[62rem] ${className || ''}`;

  return (
    <div id='board'
      className={combinedClassName}
      ref={ref}
      onClick={onClickHandler}
    >
      <PrintHelper />
      <PrintPiece />
    </div>
  );
};

export default memo(Chessboard);
