// Admin.tsx

import React, { useState } from 'react';
import Navbar from './NavBar/NavBar';
import Filter from './Filter/Filter';
import SearchBar from './Search/SearchBar';
import Table from './Table/Table';
import './Admin.css';

// Define an interface for the filters
interface Filters {
  startDate?: Date | null;
  endDate?: Date | null;
  email?: string;
  paymentMethod?: string;
}

function Admin() {
  const [filters, setFilters] = useState<Filters>({
    startDate: null,
    endDate: null,
    email: '',
    paymentMethod: ''
  });

  // Use the Filters interface for the newFilters parameter
  const handleFiltersChange = (newFilters: Partial<Filters>) => {
    // Here you can also handle additional logic if needed when filters change
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  // Modify the handleSearch function to differentiate between email and payment method
  const handleSearch = (searchTerm: string) => {
    // Check if the search term looks like an email address
    if (searchTerm.includes('@')) {
      // Update the filters state to include the email
      setFilters((prevFilters) => ({ ...prevFilters, email: searchTerm, paymentMethod: '' }));
    } else {
      // Otherwise, treat it as a payment method
      setFilters((prevFilters) => ({ ...prevFilters, paymentMethod: searchTerm, email: '' }));
    }
  };

  return (
    <div className="App">
      <Navbar />
      <div className="filterSection">
        <Filter onFiltersChange={handleFiltersChange} />
        <SearchBar onSearch={handleSearch} />
      </div>
      {/* Pass down the filters to the Table component */}
      <Table filters={filters} />
    </div>
  );
}

export default Admin;
