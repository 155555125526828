// import { button } from '@material-tailwind/react';
import React, { FC, useState } from 'react';

interface CellStyles extends React.CSSProperties{
    padding: string;
    justifyContent: string;
    alignItems: string;
    gap: string;
    flex: string;
    borderRadius: string;
    color: string;
    fontFeatureSettings: string;
    fontFamily: string;
    fontSize: string;
    fontStyle: string;
    fontWeight: string;
    lineHeight: string;
    letterSpacing: string;
    border?: string;
    background?: string;
    opacity?: string;
    pointerEvents?: 'none' | 'auto',
}

const mainContainer = {
    alignItems: 'flex-start',
    gap: '2.8125rem',
};

const tall = {
    width: '0.25rem',
    height: '9.0625rem',
    background: 'linear-gradient(0deg, rgba(25, 25, 27, 0.06) 0%, rgba(91, 243, 243, 0.24) 100%)',
};

const priceContainer = {
    alignItems: 'flex-start',
    // gap: '0.78025rem',
    height: '9.0625rem',
};

const row = {
    alignItems: 'center',
    gap: '0.58519rem',
    alignSelf: 'stretch',
    height: '100%',
};

const rowL = {
    width: '17.75rem',
    alignItems: 'center',
    gap: '0.58519rem',
    height: '100%',
};

const baseCellStyles: CellStyles = {
    padding: '0.78025rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.29256rem',
    flex: '1 0 0',
    borderRadius: '0.29256rem',
    color: '#707070',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '0.63394rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '0.87775rem',
    letterSpacing: '0.03806rem',
};

const cell1: CellStyles = {
    ...baseCellStyles,
    border: '1.17px solid rgba(112, 112, 112, 0.12)',
    color: '#707070',
};

const cell2: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(112, 112, 112, 0.20)',
    color: '#8F8F8F',
};

const cell3: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(112, 112, 112, 0.36)',
    color: '#AAA',
};

const cell4: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(112, 112, 112, 0.60)',
    color: '#BEBEBE',
};

const cell5: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(112, 112, 112, 0.80)',
    color: '#EAE9E9',
};

const cell6: CellStyles = {
    ...baseCellStyles,
    background: '#707070',
    color: '#FFF',
};

const cell7: CellStyles = {
    ...baseCellStyles,
    border: '1.17px solid rgba(250, 90, 0, 0.12)',
    color: 'rgba(250, 90, 0, 0.40)',
};

const cell8: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(250, 90, 0, 0.20)',
    color: 'rgba(250, 90, 0, 0.70)',
};

const cell9: CellStyles = {
    ...baseCellStyles,
    background: 'rgba(250, 90, 0, 0.69)',
    color: '#FFF',
};



// ... (previous imports and styles)

const AmountSelector: FC<{ onSelectAmount: (amount: number) => void; 
    accBal: number; disabled: boolean }> = ({ onSelectAmount, accBal, disabled }) => {
    const [selectedButton, setSelectedButton] = useState<string | null>(null);


    const handleButtonClick = (buttonValue: string) => {
        // if (disabled) return;
        const amount = parseInt(buttonValue.slice(0, -3));

        if (amount <= accBal) {
        setSelectedButton(buttonValue);
        onSelectAmount(amount);
        console.log(amount);
        }
    };

    const getCellStyles = (buttonValue: string): CellStyles => {
        const amountValue = parseInt(buttonValue.slice(0, -3));
        const isDisabled = amountValue > accBal || disabled;

        let baseStyle = {...baseCellStyles};

        if (selectedButton === buttonValue) {
            baseStyle = {
                ...baseStyle,
                border: '1px solid white',
            };
        }

        if (isDisabled) {
            disabled = true;
            baseStyle = {
                ...baseStyle,
                pointerEvents: 'none', // Disable pointer events
                cursor: 'default' // Change cursor to default
            };
        }

        

        switch (buttonValue) {
            case '005USD':
                return {
                    ...cell1,
                    border: selectedButton === buttonValue ? '1px solid white' : cell1.border,
                };
            case '010USD':
                return {
                    ...cell2,
                    border: selectedButton === buttonValue ? '1px solid white' : cell2.border,
                };
            case '025USD':
                return {
                    ...cell3,
                    border: selectedButton === buttonValue ? '1px solid white' : cell3.border,
                };
            case '075USD':
                return {
                    ...cell4,
                    border: selectedButton === buttonValue ? '1px solid white' : cell4.border,
                };
            case '100USD':
                return {
                    ...cell5,
                    border: selectedButton === buttonValue ? '1px solid white' : cell5.border,
                };
            case '200USD':
                return {
                    ...cell6,
                    border: selectedButton === buttonValue ? '1px solid white' : cell6.border,
                };
            case '550USD':
                return {
                    ...cell7,
                    border: selectedButton === buttonValue ? '1px solid white' : cell7.border,
                };
            case '1250USD':
                return {
                    ...cell8,
                    border: selectedButton === buttonValue ? '1px solid white' : cell8.border,
                };
            case '3000USD':
                return {
                    ...cell9,
                    border: selectedButton === buttonValue ? '1px solid white' : cell9.border,
                };
            default:
                return {
                    
                    ...baseStyle,
                    border: isDisabled
                      ? '1px solid rgba(112, 112, 112, 0.12)'
                      : '1px solid transparent',
                    pointerEvents: isDisabled ? 'none' : 'auto',
                    filter: isDisabled ? 'grayscale(100%)' : 'none', // Apply grayscale filter for not selectable buttons
                    cursor: isDisabled ? 'not-allowed' : 'pointer', // Change cursor for not selectable buttons
                };
        }
    };

    return (
        <>
        <style>
        {`
        .selBtn {
            transition: box-shadow 0.2s, border 0.2s; /* Add a smooth transition for the hover effect */
        }
        .selBtn:hover:not(.btDisabled) { 
            box-shadow: 0 0 1px 0 #ffffffcc inset, 0 0 1px 1px #ffffffcc;
            border: 1px solid #ffffffcc;
        }
        .selBtn.btDisabled {
            pointer-events: none;
            cursor: default;
            box-shadow: none;
            border: none;
            hover: none;
        }
        `}
        </style>

        <div style={mainContainer} className="inline-flex">
            <div style={tall}></div>
            <div style={priceContainer} className='flex flex-col space-y-0'>
                <div style={row} className='flex'>
                    <button style={getCellStyles('005USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('005USD')}>005USD</button>
                    <button style={getCellStyles('010USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('010USD')}>010USD</button>
                    <button style={getCellStyles('025USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('025USD')}>025USD</button>
                </div>
                <div style={row} className='flex'>
                    <button style={getCellStyles('075USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('075USD')}>075USD</button>
                    <button style={getCellStyles('100USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('100USD')}>100USD</button>
                    <button style={getCellStyles('200USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('200USD')}>200USD</button>
                </div>
                <div style={rowL} className='flex'>
                    <button style={getCellStyles('550USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('550USD')}>550USD</button>
                    <button style={getCellStyles('1250USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('1250USD')}>1250USD</button>
                    <button style={getCellStyles('3000USD')} className={`flex  ${disabled ? 'btDisabled' : 'selBtn'}`} onClick={() => handleButtonClick('3000USD')}>3000USD</button>
                </div>
            </div>
        </div>
    </>
    );
};

export default AmountSelector;

