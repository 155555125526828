import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Landing.css';
import Avatar3 from '../images/avatar3.png';
import Avatar4 from '../images/avatar4.png';

const slideIn = {
    animation: 'slideInFromLeft 1s ease-out',
    animationFillMode: 'backwards',
  };

  


const rightWrapper = {
    borderRadius: '0.9375rem',
    // padding: '5.3125rem 2.1875rem',
    gap: '0.625rem',
    
    boxShadow:
      '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    
    width: '26.375rem',
    height: '100%',
    flexShrink: 0,
    alignItems: 'center',
    // marginRight: '3.5rem',
    // Add more styles as needed
  };

  const rightWrapperAnimated = {
    ...rightWrapper,
    animation: 'slideInFromRight 1s ease-out',
  };

const wrapper = {
  width: '100%',
  minHeight: '100vh',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const mainCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '3.5625rem',
}

const topCon = {
    width: '21.9375rem',
    alignItems: 'center',
    gap: '1.6875rem',
}

const topic = {
    width: '21.9375rem',
    alignItems: 'center',
    fontFamily: 'Gugi',
    fontSize: '3rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    background: 'linear-gradient(269deg, #AAEFF6 0.42%, #588D9F 99.58%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent', 
}

const midDes = {
    alignItems: 'center',
    gap: '0.625rem',
    alignSelf: 'stretch',
    color: '#B3B3B3',
    fontFamily: 'Signika Negative',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const botDes = {
    alignItems: 'center',
    gap: '0.625rem',
    alignSelf: 'stretch',
    color: '#929292',
    fontFamily: 'Gugi',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const play = {
    alignItems: 'center',
    padding: '1.5625rem 1.875rem',
    justifyContent: 'center',
    borderRadius: '0.9375rem',
    background: 'linear-gradient(180deg, #254A58 0%, #011923 100%)',
    color: 'rgba(255, 255, 255, 0.81)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Gugi',
    fontSize: '2.25rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const bottomCon = {
    alignItems: 'center',
    gap: '1.3125rem',
}

const note = {
    color: 'rgba(255, 255, 255, 0.63)',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const copyright = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const leftCon = {
    // alignItems: 'flex-start',
    gap: '1.1875rem',
    // marginLeft: '3.5rem',
}

const label = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const countCon = {
    padding: '0rem 1.875rem',
    alignItems: 'center',
    gap: '1.375rem',
}

const line = {
    width: '0.0625rem',
    height: '7.125rem',
    background: '#FFF',
}

const count = {
    fontFamily: 'Gugi',
    fontSize: '3rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    background: 'linear-gradient(269deg, #AAEFF6 0.42%, #588D9F 99.58%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
}

const shortLine = {
    padding: '0rem 1.875rem',
    alignItems: 'flex-start',
    gap: '0.625rem',
}

const sline = {
    width: '0.0625rem',
    height: '3.625rem',
    background: '#FFF',
}

const playes = {
    // alignItems: 'flex-start',
    gap: '1.375rem',
}

const secPlayer = {
    padding: '0.75rem 0.75rem 0.75rem 0rem',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '0.3125rem',
    background: 'rgba(255, 255, 255, 0.14)',
    backdropFilter: 'blur(2px)',
}

const firstPlayer = {
    padding: '1rem 1.5rem 1rem 0rem',
    alignItems: 'center',
    gap: '1.625rem',
    borderRadius: '0.5rem',
    background: '#203B51',
    backdropFilter: 'blur(2px)',
}

const secPlCon = {
    paddingLeft: '5.625rem',
    alignItems: 'flex-start',
    gap: '0.1rem',
}

const secPlayerLabel = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const secPlyerInfo = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const secPlMatch = {
    color: '#E2E3E5',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '0.375rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const secPlImgCon = {
    width: '4.6875rem',
    height: '4.6875rem',
    bottom: '0.125rem',
    left: '0rem',
    borderRadius: '0.25rem',
    outline: '6px solid #878F93',
    background: 'url(<path-to-image>), lightgray 50% / cover no-repeat',
}

const firstPlImgcon = {
    left: '0rem',
    width: '5.1875rem',
    height: '5.1875rem',
    borderRadius: '0.375rem',
    outline: '7px solid rgba(255, 255, 255, 0.77)',
    background: 'url(<path-to-image>), #545454 50% / cover no-repeat',
}

const fisrtPlCon = {
    alignItems: 'flex-start',
    gap: '0.25rem',
}

const firstPlayerLabel = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const firstPlMatch = {
    color: '#DAD7D7',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '0.5rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const firstPlyerInfo = {
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

// const mistyVideoStyle = {
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     zIndex: '-1', // Ensure it stays in the background
//   };

  



  function useRollingNumber(startNumber: number, endNumber: number, duration: number) {
    const [currentNumber, setCurrentNumber] = useState(startNumber);
  
    useEffect(() => {
      // Generate a random target number within the range inside the effect
      const randomTarget = Math.floor(Math.random() * (endNumber - startNumber + 1)) + startNumber;
  
      let start: number | null = null;
      let animationFrameId: number;
      const range = randomTarget - startNumber;
      const targetDuration = duration * (range / (endNumber - startNumber));
  
      const step = (timestamp: number) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const progressPercentage = Math.min(progress / targetDuration, 1);
        const current = Math.round(startNumber + range * progressPercentage);
        setCurrentNumber(current);
        if (progress < targetDuration) {
          animationFrameId = window.requestAnimationFrame(step);
        }
      };
  
      animationFrameId = window.requestAnimationFrame(step);
  
      return () => {
        window.cancelAnimationFrame(animationFrameId);
      }; // Cleanup if needed
    }, [startNumber, endNumber, duration]);
  
    return currentNumber;
  }

  function getRandomPastDate() {
    // Get current date
    const currentDate = new Date();
    
    // Subtract 21 days (3 weeks) from the current date
    const pastDateLimit = new Date(currentDate);
    pastDateLimit.setDate(currentDate.getDate() - 21);
    
    // Generate a random number of days between 0 and 21
    const randomDays = Math.floor(Math.random() * 22);
    
    // Subtract the random number of days from the current date
    const randomPastDate = new Date(currentDate);
    randomPastDate.setDate(currentDate.getDate() - randomDays);
  
    // Format the date as "20th Jan 2023"
    const day = randomPastDate.getDate();
    const suffix = 
      (day === 1 || day === 21 || day === 31) ? "st" : 
      (day === 2 || day === 22) ? "nd" : 
      (day === 3 || day === 23) ? "rd" : 
      "th";
      
    const month = randomPastDate.toLocaleString('default', { month: 'short' });
    const year = randomPastDate.getFullYear();
  
    return `${day}${suffix} ${month} ${year}`;
  }

// Generates a random username
const generateRandomUsername = () => {
    const prefixes = ["Knight", "ChessMaster", "Grandmaster", "Rook", "Bishop"];
    const suffixes = ["Pro", "Genius", "Champion", "Ace", "Wizard"];
    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const suffix = suffixes[Math.floor(Math.random() * suffixes.length)];
    const num = Math.floor(Math.random() * 100);
    return `${prefix}${suffix}${num}`;
  };
  
  // Generates a random number within the specified range
  const generateRandomMatchCount = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  
  



const Landing: React.FC = () => {
    // Define the ranges such that onlineCount is always higher than inGameCount
  const totalMatches1 = generateRandomMatchCount(900, 1500);
  const totalMatches2 = generateRandomMatchCount(900, 1500);
  const randomUsername1 = generateRandomUsername();
  const randomUsername2 = generateRandomUsername();
  
  const minInGame = 1083;
  const maxInGame = 4000;
  const minOnline = maxInGame; // Minimum online count starts where inGameCount ends
  const maxOnline = 5673;

  const onlineCount = useRollingNumber(minOnline, maxOnline, 3000); // 2000 ms for animation
  const inGameCount = useRollingNumber(minInGame, maxInGame, 4000);

  const [randomDate, setRandomDate] = useState('');

  useEffect(() => {
    setRandomDate(getRandomPastDate());
  }, []);

  


  return (
    <div id='mainCon' style={wrapper} className='flex relative' >
        {/* <video autoPlay loop muted style={mistyVideoStyle} className='absolute object-cover'>
        <source src=".../images/misty.mp4" type="video/mp4" />
        Your browser does not support the video tag.
        </video> */}
        <div style={leftCon} className='botCon'>
            <div className='flex flex-col cenDiv' style={{ ...leftCon, ...slideIn, animationDelay: '0.1s' }}>
                <p style={{ ...label, ...slideIn, animationDelay: '0.2s' }}>ONLINE</p>
                <div className='flex' style={{ ...countCon, ...slideIn, animationDelay: '0.3s' }}>
                    <div style={line}></div>
                    <p style={count}>{onlineCount}</p>
                </div>
                <p style={{ ...label, ...slideIn, animationDelay: '0.4s' }}>INGAME</p>
                <div className='flex' style={{ ...countCon, ...slideIn, animationDelay: '0.5s' }}>
                    <div style={line}></div>
                    <p style={count}>{inGameCount}</p>
                </div>
                <p style={{ ...label, ...slideIn, animationDelay: '0.6s' }}>TOP KNIGHTS</p>
                <div className='flex' style={{ ...shortLine, ...slideIn, animationDelay: '0.7s' }}>
                    <div style={sline}></div>
                </div>
                <div className='flex flex-col plcon' style={{ ...playes, ...slideIn, animationDelay: '0.8s' }}>
                    <div className='flex flex-col' style={{ ...secPlayer, ...slideIn, animationDelay: '0.9s' }}>
                        <div className='flex flex-col' style={secPlCon}>
                            <p style={secPlayerLabel}>Username : <span style={secPlyerInfo}>{randomUsername1}</span></p>
                            <p style={secPlayerLabel}>Target price : <span style={secPlyerInfo}>3000$</span></p>
                            <p style={secPlayerLabel}>Total matches : <span style={secPlyerInfo}>{totalMatches1}</span></p>
                            <p style={secPlMatch}>First match in {randomDate}</p>
                        </div>
                        <div className='absolute' style={secPlImgCon}>
                            <img 
                                src={totalMatches1 >= 1000 ? Avatar4 : Avatar3} 
                                alt="Avatar"
                            />
                        </div>
                    </div>
                    <div className='flex' style={{ ...firstPlayer, ...slideIn, animationDelay: '1s' }}>
                        <div style={firstPlImgcon} id='firstPlayIcon'>
                            <img 
                                src={totalMatches2 >= 1000 ? Avatar4 : Avatar3} 
                                alt="Avatar"
                            />
                        </div>
                        <div className='flex flex-col' style={fisrtPlCon} id='first'>
                            <p style={firstPlayerLabel}>Username : <span style={firstPlyerInfo}>{randomUsername2}</span></p>
                            <p style={firstPlayerLabel}>Target price : <span style={firstPlyerInfo}>3000$</span></p>
                            <p style={firstPlayerLabel}>Total matches : <span style={firstPlyerInfo}>{totalMatches2}</span></p>
                            <p style={firstPlMatch}>First match in {randomDate}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={rightWrapperAnimated} className='topOne' id='tOne'>    
            <div className="flex flex-col" style={mainCon}>
                <div className="flex flex-col" style={topCon}  id='rCol'>
                    <p className="flex flex-col" style={topic}>BE A KNIGHT</p>
                    <p className="flex text-justify" style={midDes}>Introducing an exhilarating online chess platform where strategic brilliance meets thrilling stakes. Play chess with global opponents in real-time and bet on your victories to elevate the excitement. Experience the fusion of skill and risk on our multiplayer platform, where every move counts and every win brings rewards.</p>
                    <p className="flex text-justify" style={botDes}>Chess.com is not targeted towards minors, meaning a person under the minimum age required to consent to the processing of their personal information as determined by applicable laws in their state or country of residence.</p>
                </div>
                <button className='flex flex-col btnPl relative' style={play}>
                <Link to="/login" className='sLink'>PLAY NOW</Link>
                </button>
                <div className="flex flex-col" style={bottomCon}>
                    <p className="flex text-justify" style={note}>Note : This website is not gambling.<br/> All we do is provide value to talent.</p>
                    <p className="flex text-center" style={copyright}>ALL RIGHTS RESERVED 2023</p>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Landing;
