import { FC, useEffect } from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Chessengine } from './Chessengine';
// import Footer from './Footer';
import { Chessboard, RightColl } from './Game';
// import Withdraw from './components/Withdraw';
import Login from './components/Login';
import Landing from './components/Landing';
// import Modal from './Chessengine/Modal';
// import Board from './images/Chessboard-1.svg';
import homebg from './images/homebg.png';
import homebg2 from './images/homebg2.png';
import './App.css';
import axios from 'axios';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Admin from './components/Admin/Admin';



const right = {
  // height: '100%',
  // alignItems: 'left',
  // marginRight: 'auto',
}

const container = {
  // width: 'fit-content',
}

const board = {
  // marginRight: '8rem',
  // '@media (max-width: 768px)': {
  //   marginRight: '2rem',
  // },
}

const holder = {
  opacity: '0.5',
  marginBottom: '2rem',
}


const App: FC = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(localStorage.getItem('isAdminLoggedIn') === 'true');
  const [isChessboardVisible, setChessboardVisible] = useState(false);
  const [chessboardClass, setChessboardClass] = useState('');
  // const [backgroundImage, setBackgroundImage] = useState(`url(${homebg})`);

  const handleLogin = async (email: string, password: string) => {
    // Admin credentials check (For demonstration purposes only, not secure)
  if (email === 'admin' && password === '200122542admin') {
    setAdminLoggedIn(true);
    console.log('Admin login successful');
    navigate('/admin'); // Navigate to admin route
    return; // Exit the function to avoid further processing
  }
    try {
      const response = await axios.post(`/api/auth/login`, { email, password });
      if (response.data) {
        console.log(`Logged in with token: ${response.data.token}`);
        setLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('token', response.data.token); // Save the token in local storage
        const userID = response.data._id;
        localStorage.setItem('userID', userID)
        navigate('/app');
        ;
      }
    } catch (error) {
      console.error('Login failed', error);
      // Handle error (e.g., show an error message)
    }
  };
  

// Function to handle logout

const handleLogout = () => {
  setLoggedIn(false);
  localStorage.removeItem('isLoggedIn'); // Clear isLoggedIn from localStorage
  navigate('/login');
};


  const handleCloseModal = () => {
    navigate('/app');
  };

  
  
  // Function to change background image
  const changeBackgroundImage = () => {
    // Create a new overlay div
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.left = '0';
    overlay.style.top = '0';
    overlay.style.width = '100vw';
    overlay.style.height = '100vh';
    overlay.style.backgroundImage = `url(${homebg2})`;
    overlay.style.backgroundSize = 'cover';
    overlay.style.backgroundPosition = 'center center';
    overlay.style.backgroundRepeat = 'no-repeat';
    overlay.style.opacity = '0';
    overlay.style.transition = 'opacity 3s ease-in-out';
    overlay.style.zIndex = '-1'; // Ensure it's behind other content
  
    // Append the overlay to the body
    document.body.appendChild(overlay);
  
    // Trigger the transition
    setTimeout(() => {
      overlay.style.opacity = '1';
    }, 10); // Start after a very short delay to ensure the transition effect
  
    // Clean up: remove the overlay after the transition is complete
    overlay.addEventListener('transitionend', () => {
      document.body.style.backgroundImage = `url(${homebg2})`;
      document.body.removeChild(overlay);
    });
  };
  

  const toggleChessboardVisibility = () => {  
    if (isChessboardVisible) {
      setChessboardClass('chessboard-exit');
      setTimeout(() => {
        setChessboardClass('chessboard-exit-active');
        setTimeout(() => {
          setChessboardVisible(false);
        }, 3000); // This should match the transition time
      }, 10);
    } else {
      setChessboardVisible(true);
      setChessboardClass('chessboard-enter');
      setTimeout(() => {
        setChessboardClass('chessboard-enter-active');
        changeBackgroundImage();
      }, 10);
    }
  };

  // Use useEffect to revert to the initial background when the component unmounts
  useEffect(() => {
    // Set the initial background image on the body
    document.body.style.backgroundImage = `url(${homebg})`;
    // document.body.style.transition = 'background-image 3s ease-in-out';

    // Clean up function to reset the background when the App component is unmounted
    return () => {
      document.body.style.backgroundImage = '';
      // document.body.style.transition = '';
    };
  }, []);
  
  return (
    // <div style={{ backgroundImage: backgroundImage, transition: 'background-image 0.5s ease-in-out' }} className='bg-no-repeat bg-cover bg-center absolute w-full h-full'>
      <Routes>
        <Route
          path="/"
          element={<Landing />}
        />
        <Route 
          path="/login" 
          element={<Login onLogin={handleLogin} />} 
        />
        <Route
          path='/admin'
          element={
            isAdminLoggedIn? (<Admin />
            ) : (
              <Navigate to="/login" replace/>
            )
          }
        />
        <Route 
          path="/forgotpassword" 
          element={<ForgotPassword />} 
        />
        <Route 
          path="/resetpassword/:token" 
          element={<ResetPassword/>} 
        />
        <Route 
          path="/register" 
          element={<Register />} 
          />
        <Route
          path="/app"
          element={
            isLoggedIn ? (
              <div id='pad' className="mt-10 flex flex-col flex-wrap lg:flex-row" style={container}>
                {/* w-full */}
                <Chessengine>
                  <div id='board' className="mx-5 shrink grow-[2]" style={board}>
                    {isChessboardVisible ? (
                      <Chessboard className={`transition-piece ${chessboardClass}`} />
                    ) : (
                      <div style={holder} className="relative mx-auto h-vw-5 w-vw-5 bg-Chessboard-1 bg-contain bg-no-repeat lg:h-vh-5 lg:max-h-[42rem] lg:min-h-[28rem] lg:w-vh-5 lg:min-w-[28rem] lg:max-w-[42rem] xl:max-h-[62rem] xl:max-w-[62rem]"></div>
                    )}
                  </div>
                  <div id='down' className="shrink grow" style={right}> 
          {/* my-10 */}
                    <RightColl onPlayButtonClick={toggleChessboardVisibility}/>
                  </div>
                </Chessengine>
                {/* Force break line */}
                {/* <div className="basis-full"></div> */}
                {/* <Footer /> */}
              </div>
            ) : (
              <Navigate to="/login" replace/>
            )
          }
        />
      </Routes>
    // </div>
  );
};

export default App;