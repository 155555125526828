import classNames from 'classnames';
import { FC, HtmlHTMLAttributes } from 'react';

interface ButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}
/**
 * Base component button
 */
const Button: FC<ButtonProps> = ({
  className,
  disabled,
  ...props
}) => <button {...props} className={classNames(className)} disabled={disabled}/>;

export default Button;
