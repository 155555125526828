import React, { FC,useState } from 'react';
import Knight from '../Assets/knight.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

// interface LoginProps {
//   onLogin: (email: string, password: string) => void;
// }

const wrapper = {
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.60)',
};


const mainContainer = {
    justifyContent: 'center',
    padding: '6.25rem 6.125rem',
    alignItems: 'center',
    borderRadius: '0.9375rem',
    background: 'rgba(33, 51, 63, 0.01)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05), 0px 4px 56.1px 0px rgba(204, 199, 123, 0.08)',
    margin: 'auto',
};

const bgImg = {
    width: '19.8125rem',
    height: '100%',
    right: '0rem',
    zIndex: -2,
};

const midContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5.25rem',
};

const topCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '3rem',
};

const topicCon = {
    alignItems: 'center',
    gap: '1.25rem',
};

const topic = {
    alignItems: 'center',
    gap: '0.375rem',
    color: 'var(--White, #FFF)',
    fontFamily: 'Poppins',
    fontSize: '2.625rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '112%', /* 2.94rem */
};

const des = {
    color: '#FFF',
    // textAlign: 'center',
    /* Body - Secondary/Body - Base/Medium */
    fontFamily: 'Poppins',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
};

const inputCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2.25rem',
    alignSelf: 'stretch',
};

const inputs = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
};

const fpbCon = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.75rem',
}

const input = {
    color: '#FFF',
    // width: '23.375rem',
    width: '350px',
    padding: '0.75rem 1rem',
    alignItems: 'center',
    gap: '1.23719rem',
    borderRadius: '0.1875rem',
    border: '1px solid #838383',
    background:' rgba(0, 3, 17, 0.24)',
};


const fpb = {
    color: '#CCC77B',
    // textAlign: 'right',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '0.75rem', /* 100% */
};

const registerBtn = {
    padding: '0.5rem 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.23719rem',
    alignSelf: 'stretch',
    borderRadius: '0.1875rem',
    border: '1px solid #CCC77B',
    background: 'rgba(247, 147, 26, 0.25)',
    color: '#CCC77B',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
}

const reg = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
}

const logDes = {
    color: '#FFF',
    // textAlign: 'center',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '0.75rem',
}

const loginBtn = {
    color: '#F7931A',
    // textAlign: 'center',

    /* Body - Tertury/Regular */
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const Register: FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

  // Example: Call onLogin when the login button is clicked
  const handleRegClick = async () => {
    if (password !== confirmPassword) {
        // Handle the case where passwords don't match
        console.log("Passwords do not match");
        return;
    }

    try {
        const response = await axios.post(`/api/auth/register`, {
            username,
            email,
            phoneNumber,
            password,
            confirmPassword
        });

        if (response.data) {
            console.log("Registration successful");
            navigate('/login'); // Redirect to login page after successful registration
        }
    } catch (error: any) {
        console.error("Registration failed", error.response.data);
    }
};


  const handleLogBtn = () => {
    navigate('/login');
  }


  return (
    <div className='flex' style={wrapper}>
    <div style={mainContainer} className='inline-flex mainCon'>
      
      <div style={midContainer} className='flex flex-col'>
        <div style={topCon} className='flex flex-col'>
            <div style={topicCon} className='flex flex-col'>
                <p style={topic} className='flex'>BE A KNIGHT</p>
                <p style={des}>Be ready for the battle</p>
            </div>
            <div style={inputCon} className='flex flex-col'>
                <div style={inputs} className='flex flex-col'>
                    <input 
                        style={input} 
                        className='flex' 
                        placeholder='Username' 
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input 
                        style={input} 
                        className='flex' 
                        placeholder='Email' 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input 
                        style={input} 
                        className='flex' 
                        placeholder='Phone Number' 
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <input 
                        style={input} 
                        className='flex' 
                        placeholder='Password'
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                    /> 
                    <input 
                        style={input} 
                        className='flex' 
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        type='password'
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    /> 
                    
                </div> 
                <button style={registerBtn} className='flex' onClick={handleRegClick}>REGISTER</button>              
            </div>
            <div style={reg} className='flex flex-col'>
                <p style={logDes}>Already have an account? &nbsp; <button onClick={handleLogBtn} style={loginBtn}>LOG IN</button></p>
            </div>
        </div>
      </div>
      <img src={Knight} alt="knight" style={bgImg} className='absolute' />
      {/* Your login form and other content */}
      {/* <button onClick={handleLoginClick}>Login</button> */}
    </div>
    </div>
  );
};

export default Register;
