

import React, { useCallback, useEffect, useState } from 'react';
import styles from './Table.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface DepositData {
  depCount: number;
  name: string;
  paymentMethod: string;
  email: string;
  date: string;  // assuming date is a string, format can be adjusted
  depositAmount: number;
  refNumber: string;
}

interface Filters {
  startDate: Date | null;
  endDate: Date | null;
  email: string;
  paymentMethod: string;
}

interface TableProps {
  filters: Partial<Filters>;
}

const Table: React.FC<TableProps> = ({ filters }) => {
  const [localDeposits, setLocalDeposits] = useState<DepositData[]>([]);

  const fetchDeposits = useCallback(async () => {
    try {
      // Construct query parameters from filters
      const params = new URLSearchParams({
        ...(filters.startDate && { startDate: filters.startDate.toISOString() }),
        ...(filters.endDate && { endDate: filters.endDate.toISOString() }),
        ...(filters.email && { email: filters.email }),
        ...(filters.paymentMethod && { paymentMethod: filters.paymentMethod }),
      });

      const response = await axios.get(`/api/deposits?${params}`);
      setLocalDeposits(response.data);
    } catch (error) {
      console.error('Error fetching filtered deposits:', error);
      // Handle error appropriately, possibly set an error state and display a message
    }
  }, [filters.email, filters.endDate, filters.paymentMethod, filters.startDate]);

  useEffect(() => {
    console.log('Filters:', filters);
    fetchDeposits();
  }, [fetchDeposits, filters]);

  const approveBtn = async (deposit: any) => {
    const response = await axios.post('/api/approve', {
      refNo: deposit.refNumber
    })
    fetchDeposits()
    if(response.status === 200){
      Swal.fire({
        title: "Success",
        text: "Request Approved",
        icon: "success"
      });
    }
  }

  const rejectBtn = async (deposit: any) => {
    const response = await axios.post('/api/reject', {
      refNo: deposit.refNumber
    }
  )
  fetchDeposits()
    if(response.status === 200){
      Swal.fire({
        title: "Rejected",
        text: "Request Rejected",
        icon: "error"
      });
    }
  }

  function refreshTable(){
    fetchDeposits()
  }

  return (
    <div className={styles.tableContainer}>
    <button onClick={()=>{refreshTable()}} className={styles.reject_btn}>Refresh</button>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Deposit NO.</th>
            <th>Payment Method</th>
            <th>Name</th>
            <th>Email</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Reference Number</th>
            <th>Approve</th>
            <th>Reject</th>
          </tr>
        </thead>
        <tbody>
          {localDeposits.map((deposit, index) => (
            <tr key={deposit.refNumber}>
              <td>{deposit.depCount}</td>
              <td>{deposit.paymentMethod}</td>
              <td>{deposit.name}</td>
              <td>{deposit.email}</td>
              <td>{deposit.date}</td>
              <td>{deposit.depositAmount}</td>
              <td>{deposit.refNumber}</td>
              <td>
                <button onClick={() => approveBtn(deposit)} className={styles.approve_btn}>Approve</button>
              </td>
              <td>
                <button onClick={() => rejectBtn(deposit)} className={styles.reject_btn}>Reject</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
