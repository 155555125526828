import type { configObj } from 'js-chess-engine';
import { FC } from 'react';
import { PiecePoint } from '../../../Chessengine';
import Timer from './Timer';

type input = {
  data: configObj;
};
const CustomInfo: FC<input> = ({ data }) => {
  let white: number = 0;
  let black: number = 0;
  for (const piece in data.pieces) {
    const FenLetter = data.pieces[piece];
    const value = PiecePoint[FenLetter];
    if (FenLetter === FenLetter.toUpperCase()) white += value;
    else black += value;
  }

const mainCon = {
  alignItems: 'flex-end',
  gap: '0.48763rem',
  }

const left = {
  width: '8.75rem',
  alignItems: 'flex-start',
  gap: '0.39013rem',
}

const right = {
  width: '11.36194rem',
  alignItems: 'flex-start',
  gap: '0.48763rem',
}

const topic = {
  padding: '0.29256rem 0.58519rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.48763rem',
  alignSelf: 'stretch',
  borderRadius: '0.14631rem',
  background: '#1E627B',
  color: '#FFF',
  fontFamily: 'Offside',
  fontSize: '0.78025rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.10144rem',
}

const rightCon = {
  alignItems: 'flex-start',
  gap: '0.39013rem',
  flex: '1 0 0',
  color: '#FFF',
}

const mDataW = {
  padding: '0.29256rem 0.58519rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.48763rem',
  alignSelf: 'stretch',
  borderRadius: '0.14631rem',
  background: '#B8B8B8',
  color: '#191919',
  fontFamily: 'Offside',
  fontSize: '0.78025rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.10144rem',
}

const mDataB = {
  padding: '0.29256rem 0.58519rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.48763rem',
  alignSelf: 'stretch',
  borderRadius: '0.14631rem',
  background: '#2C2C2C',
  color: '#FFF',
  fontFamily: 'Offside',
  fontSize: '0.78025rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.10144rem',
}


  return (
    <div style={mainCon} className='inline-flex'>
      <div style={left} className='flex flex-col'>
        <div style={topic} className='flex'>
          <p>POINTS</p>
        </div>
        <div style={topic} className='
        flex'>
          <p>TIME</p>
        </div>
      </div>
      <div style={right} className='flex'>
        <div style={rightCon} className='flex flex-col'>
          <div style={mDataW} className='flex'>
              <p>{white - black}</p>
          </div>
          <div style={mDataW} className='flex'>
              <p><Timer type="white" data={data} /></p>
          </div>
        </div>
        <div style={rightCon} className='flex flex-col'>
        <div style={mDataB} className='flex'>
              <p>{black - white}</p>
          </div>
          <div style={mDataB} className='flex'>
              <p><Timer type="black" data={data} /></p>
          </div>
        </div>
      </div>
      {/* <div className="mb-5 columns-3">
        <p className="text-transparent">.</p>
        <p>Point:</p>
        <p>Timer:</p>
        <p>White</p>
        <p>{white - black}</p>
        <p>
          <Timer type="white" data={data} />
        </p>
        <p>Black</p>
        <p>{black - white}</p>
        <p>
          <Timer type="black" data={data} />
        </p>
      </div> */}
    </div>
  );
};

export default CustomInfo;
