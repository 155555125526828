import React, { FC, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import btcIcon from '../Assets/btcIcon.svg';
import ethIcon from '../Assets/ethIcon.svg';
import tethIcon from '../Assets/tethIcon.svg';
import BTC from '../images/btcQR.png'
import ETH from '../images/ethQR.png'
import TETH1 from '../images/teth1QR.png'
import TETH2 from '../images/teth2QR.png'
import axios from 'axios';
import './Deposit.css';

interface DepositProps {
    onClose: () => void;
}

const modalContainer = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.60)',
    backdropFilter: 'blur(10px)',
    // alignItems: 'center',
    // justifyContent: 'center',
};

const modelContent = {
    // background: '#fff',
    // padding: '20px',
    // borderRadius: '8px',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    // maxWidth: '80%',
    // maxHeight: '80%',
    // overflow: 'auto',
    // padding: '2.1875rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    borderRadius: '0.9375rem',
    // border: '2.5px solid #21333F',
    background: 'rgba(33, 51, 63, 0.01)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    // backdropFilter: 'blur(71.5622329711914px)',
};

const closebtn = {
    padding: '0.1875rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.625rem',
    alignSelf: 'stretch',
    cursor: 'pointer',
};

const mainContainer = {
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2.6875rem',
};

const note = {
    // width: '44.6875rem',
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
}

const paymentMethods = {
    // width: '44.625rem',
    alignItems: 'center',
    gap: '1.125rem',
}

const bottom = {
    // width: '44.625rem',
    // alignItems: 'flex-start',
    // gap: '1.8125rem',
}

const row = {
    justifyContent: 'center',
    alignItems: 'flex-start',
    // gap: '1.875rem',
    alignSelf: 'stretch',
}

const btn1 = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid #F7931A',
    background: 'rgba(247, 147, 26, 0.29)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const btn2 = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid rgba(98, 126, 234, 0.35)',
    background: 'rgba(26, 114, 247, 0.10)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const btnT = {
    padding: '0.75rem 1.75rem',
    alignItems: 'center',
    gap: '1.75rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1.5px solid rgba(38, 161, 123, 0.35)',
    background: ' rgba(26, 247, 154, 0.10)',
    boxShadow: '-0.73px 0.73px 0.73px -1.46px rgba(255, 255, 255, 0.35) inset, 0px 17.525px 21.907px 0px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(71.5622329711914px)',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const left = {
    // width: '21.375rem',
    // alignItems: 'flex-start',
    gap: '2.0625rem',
    flexShrink: '0',
}

const right = {
    alignItems: 'flex-start',
    gap: '0.375rem',
    flex: '1 0 0',
}

const info = {
    alignItems: 'flex-start',
    gap: '1.0625rem',
    alignSelf: 'stretch',
}

const steps = {
    alignItems: 'flex-start',
    gap: '0.25rem',
    alignSelf: 'stretch',
}

const Label = {
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const Data = {
    flex: '1 0 0',
    alignSelf: 'stretch',
    color: 'rgba(255, 255, 255, 0.70)',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const depositPart = {
    width: '100%',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
}

const finalPart = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
}

const input = {
    width: '100%',
    padding: '0.75rem 1rem',
    alignItems: 'center',
    gap: '1.23719rem',
    flex: '1 0 0',
    borderRadius: '0.375rem',
    border: '1px solid #838383',
    background:' rgba(0, 3, 17, 0.24)',
    color: '#FFF',
}

const depositBtn = {
    width: '6.75rem',
    padding: '0.6875rem 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.23719rem',
    borderRadius: '0.375rem',
    border: '1px solid #CCC77B',
    background: 'rgba(247, 147, 26, 0.25)',
    color : '#CCC77B',
}

const qrContainer = {
    height: '21.4375rem',
    alignItems: 'center',
    gap: '0.75rem',
    alignSelf: 'stretch',
    background: 'rgba(64, 35, 0, 0.25)',
}

const addressContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.625rem',
    alignSelf: 'stretch',
}

const address = {
    color: 'rgba(255, 255, 255, 0.44)',
    fontFamily: 'Open Sans',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
}

const CloseIcon: FC = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M24.1096 0.390466C23.589 -0.130155 22.7448 -0.130155 22.2243 0.390466L12.5 10.1147L2.77577 0.390466C2.25516 -0.130155 1.41107 -0.130155 0.890466 0.390466C0.369845 0.911075 0.369845 1.75516 0.890466 2.27577L10.6147 12L0.890493 21.7242C0.369871 22.2449 0.369871 23.0889 0.890493 23.6096C1.4111 24.1302 2.25519 24.1302 2.7758 23.6096L12.5 13.8853L22.2243 23.6096C22.7448 24.1302 23.589 24.1302 24.1096 23.6096C24.6301 23.0889 24.6301 22.2449 24.1096 21.7243L14.3853 12L24.1096 2.27577C24.6301 1.75516 24.6301 0.911075 24.1096 0.390466Z" fill="#21333F"/>
    </svg>
  );

const refSubCon = {
    width: '16rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    rowGap: '1rem',
}

const timerCon = {
    justifyContent: 'center',
    alignItems: 'center',
}

const countDwn = {
    color: '#949494',
    fontFamily: 'Open Sans',
    fontSize: '0.9rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
}

const holder = {
    color: '#949494',
    fontFamily: 'Open Sans',
    fontSize: '0.7rem',
    fontStyle: 'normal',
    fontweight: '500',
    lineHeight: 'normal',
};

const selectedBtn = {
    border: '1.5px solid #ffffff', // Example border color for selected state
    // background: 'rgba(0, 255, 0, 0.2)', // Example background color for selected state
    // Other styles for the selected state
};


const PaymentButton = ({ method, currentMethod, onClick, icon, label }: { method: string, currentMethod: string, onClick: (method: string) => void, icon: string, label: string }) => {
    let buttonStyle;
    const isSelected = currentMethod === method;
    switch (method) {
        case 'btc':
            buttonStyle = {...btn1, ...(isSelected && selectedBtn)};
            break;
        case 'eth':
            buttonStyle = {...btn2, ...(isSelected && selectedBtn)};
            break;
        default:
            buttonStyle = {...btnT, ...(isSelected && selectedBtn)};
    }

    return (
        <button
            style={buttonStyle}
            className={`btn flex ${currentMethod === method ? 'selected' : ''}`}
            onClick={() => onClick(method)}>
            <img src={icon} alt={label} />
            {label}
        </button>
    );
};



const Deposit: FC<DepositProps> = ({ onClose }) => {
    const modelRoot = document.getElementById('modal-root') || document.body;
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [selectedMethod, setSelectedMethod] = React.useState('btc');
    const [isFinalPartVisible, setIsFinalVisible] = useState(false);
    const [isDepositPartVisible, setIsDepositPartVisible] = useState(true);
    const [depositAmount, setDepositAmount] = useState('');
    const [timer, setTimer] = useState(300); // 300 seconds for 5 minutes
    const [refNumber, setRefNumber] = useState('');

    const handleDepositAmountChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setDepositAmount(e.target.value);
    };

    const qrCodeImages = {
        btc: BTC,
        eth: ETH,
        teth1: TETH1,
        teth2: TETH2,
    };

    const addresses = {
        btc: '1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2',
        eth: '0x8A1cD7E3Ae5bA2c2C9f1A5aD7A9d9eA95B1f1Dd6',
        teth1: '1srf1dg56151sd1v5dgvs15d1s5v1s3d5v',
        teth2: 'as1a68v18e5f16v81e816516v85a1f68vv651',
    };

    const handlePaymentMethodChange = (method: string) => {
        setSelectedMethod(method);
    };

    const handleAddressClick = (address: string) => {
        navigator.clipboard.writeText(address);
    };

    const handleDepBtnClick = () => {
        setIsFinalVisible(true);
        setIsDepositPartVisible(false);
        // Start the countdown timer
        const intervalId = setInterval(() => {
            setTimer((prevTime) => {
                const newTime = prevTime - 1;
                if (newTime === 0) {
                    clearInterval(intervalId);
                    setIsFinalVisible(false);
                    setIsDepositPartVisible(true);
                }
                return newTime;
            });
        }, 1000);
    };

    const handleRefNumeberChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setRefNumber(e.target.value);
    };

    const handleRefSubmit = async () => {
        const userId = localStorage.getItem('userID');
        try {
            const response = await axios.post(`/api/deposit`, {
                userId,
                depositAmount: Number(depositAmount),
                refNumber,
                paymentMethod: selectedMethod,
            });
    
            console.log('Deposit successful:', response.data);
            // Optionally perform actions based on the successful deposit
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Now we can safely access error.response, error.request, and error.message
                if (error.response) {
                    console.error('Deposit failed:', error.response.data);
                    // More detailed logging can be added here
                } else if (error.request) {
                    console.error('No response from server:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            } else {
                // Error is not an AxiosError
                console.error('An unexpected error occurred:', error);
            }
        }
    
        setIsFinalVisible(false);
        setIsDepositPartVisible(true);
        window.location.reload();
      
    };
    
    

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

  return ReactDOM.createPortal(
    <div style={modalContainer} className='fixed flex modCon'>
        <div style={modelContent} className='relative flex-col mainConn' ref={modalRef}>
            <div style={closebtn} className='flex' onClick={onClose}>
               <CloseIcon />
            </div>   
            <div style={mainContainer} className='flex flex-col core'>
                <p style={note} className='para'>The minimum deposit amount is 10USD. All deposits below the limit will be lost. Carefully check the address. The transaction will be lost if the address is incorrect.</p>
                <div style={paymentMethods} className='flex flex-col oUT'>
                    <div style={row} className='flex btCon'>
                        <PaymentButton
                            method="btc"
                            currentMethod={selectedMethod}
                            onClick={handlePaymentMethodChange}
                            icon={btcIcon}
                            label="BITCOIN"
                        />
                        <PaymentButton
                            method="eth"
                            currentMethod={selectedMethod}
                            onClick={handlePaymentMethodChange}
                            icon={ethIcon}
                            label="ETHEREUM ERC20"
                        />
                        
                    </div>
                    <div style={row} className='flex btCon'>
                        <PaymentButton
                            method="teth1"
                            currentMethod={selectedMethod}
                            onClick={handlePaymentMethodChange}
                            icon={tethIcon}
                            label="THETHER TRC20"
                        />
                        <PaymentButton
                            method="teth2"
                            currentMethod={selectedMethod}
                            onClick={handlePaymentMethodChange}
                            icon={tethIcon}
                            label="THETHER ERC20"
                        />
                    </div>
                </div>
                <div style={bottom} className='flex dCon'>
                    <div style={left} className='flex flex-col dep'>
                        <div style={info} className='flex flex-col'>
                            <div style={steps} className='flex flex-col'>
                                <p style={Label}>Step 1</p>
                                <p style={Data}>Choose a deposit method and deposit minimum 10USD  (use QR code)</p>
                            </div>
                            <div style={steps} className='flex flex-col'>
                                <p style={Label}>Step 2</p>
                                <p style={Data}>Enter the reference number of your transaction.</p>
                            </div>
                        </div>
                        <div style={{...depositPart, display: isDepositPartVisible ? 'flex' : 'none'}} className='flex'>
                            <input 
                                style={input} 
                                className='flex' 
                                placeholder='Deposit amount (10USD+)' 
                                value={depositAmount}
                                onChange={handleDepositAmountChange}
                                type='number'
                            />
                            <button 
                                style={depositBtn} 
                                className='flex' 
                                onClick={handleDepBtnClick}
                                disabled={Number(depositAmount) < 10}
                            >
                                Deposit
                            </button>
                        </div>
                        <div style={{...finalPart, display: isFinalPartVisible ? 'flex' : 'none'}} className='flex flex-col'>
                            <input 
                                style={input} 
                                className='flex'
                                placeholder='Ref. number' 
                                value={refNumber}
                                onChange={handleRefNumeberChange}
                            />
                            <div style={refSubCon} className='flex flex-wrap'>
                                <button style={depositBtn} className='flex' onClick={handleRefSubmit}>Sumbit</button>
                                <div style={timerCon} className='flex flex-col'>
                                    <p style={countDwn}>{`${Math.floor(timer / 60)}:${('0' + timer % 60).slice(-2)}`}</p>
                                    <p style={holder}>Remaining</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={right} className='flex flex-col'>
                        <div style={qrContainer} className='flex flex-col'>
                            <img src={qrCodeImages[selectedMethod as keyof typeof qrCodeImages]} alt={`${selectedMethod} qr code`} />
                        </div>
                        <div style={addressContainer} className='flex cursor-pointer'>
                            <p style={address} onClick={() => handleAddressClick(addresses[selectedMethod as keyof typeof addresses])}>{addresses[selectedMethod as keyof typeof addresses]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>,
    modelRoot
  );
};

export default Deposit;